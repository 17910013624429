.page-not-found-section {
    max-width: 100vw;
    min-height: 100vh;
    position: relative;
    padding-bottom: 150px;
}

.page-not-found-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 60px;
    width: 90%;
    margin-left: auto;
}

.page-not-found-container p {
    font-size: 16px;
    position: relative;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--primary-color);
}

.page-not-found-container p::before {
    content: "— ";
    font-size: 24px;
    color: var(--primary-color);
}

.page-not-found-container h1 {
    font-size: 62px;
    padding-bottom: 10px;
    color: var(--secondary-color);
}


.page-not-found-container h3 {
    font-size: 22px;
    padding-bottom: 5px;
    color: var(--secondary-color);
}

.page-not-found-container a {
    padding: 18px 50px;
    background-color: var(--secondary-color);
    width: fit-content;
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 30px;
    cursor: pointer;
    transition: 0.3s;
    width: 250px;
    text-align: center;
    border-radius: 3px;
}

.page-not-found-container a i {
    padding-right: 20px;
}

.page-not-found-container a:hover {
    background-color: var(--primary-color);
}

@media(max-width: 850px) {
    .page-not-found-section {
        max-width: 100vw;
        height: 100%;
        position: relative;
    }

    .page-not-found-container {
        padding: 50px 0px;
        padding-bottom: 250px;
    }
}