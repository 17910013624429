.about-us-main {
    width: 100%;
    color: var(--secondary-color);
    min-height: 100vh;
    position: relative;
}

.about-us-main .about-us-image {
    height: 85vh;
    padding: 0;
    margin: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("/public/Asserts/About/intro_c.jpeg");
    display: flex;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    top: 0px;
    width: 100%;
}

.aboutUsInformation {
    width: 50%;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 50px;
}

.aboutUsMainContainer h1 {
    font-size: 36px;
    padding-bottom: 45px;
    margin: 0;
}

.aboutUsMainContainer h3 {
    font-size: 22px;
    line-height: 1.5em;
    padding-bottom: 35px;
    text-align: justify;
    margin: 0;
}

.aboutUsMainContainer p {
    font-size: 16px;
    line-height: 1.5em;
    text-align: justify;
}

.aboutUsVM {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
    margin: 0;
}

.aboutUsVM p {
    flex: 2;
    margin: 0;
}

.aboutUsVM h4 {
    flex: 1;
    font-size: 22px;
    margin: 0;
}

.aboutUsMainBody {
    padding-top: 50px;
}

.about-us-features {
    width: 80%;
    margin: auto;
    display: flex;
    padding-bottom: 150px;
}

.about-us-features-container {
    flex: 1;
    padding: 50px;
}

.about-us-features-container img {
    width: 60px;
    height: 60px;
}

.about-us-features h3 {
    padding: 30px 0px;
    font-size: 22px;
}

.about-us-features p {
    line-height: 25px;
    text-align: justify;
    font-size: 16px;
}


/**about us - contact-us global**/

.page-titles {
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
    margin: auto;
    justify-content: center;
    padding-top: 100px;
    margin-bottom: 100px;
}

.entry-titles {
    font-size: 80px;
    padding-bottom: 35px;
    font-weight: 5px;
}

.entry-subtitles p {
    text-align: center;
    width: 100%;
    margin: auto;
    font-size: 34px;
}

.entry-subtitles {
    font-size: 24px;
    line-height: 1.5em;
    letter-spacing: 1.2px;
}

@media(max-width: 1050px) {
    .aboutUsInformation {
        width: 70%;
    }

    .about-us-main .about-us-image {
        height: 75vh;
    }
}

@media(max-width: 850px) {
    .aboutUsInformation {
        width: 80%;
    }

    .about-us-features {
        flex-direction: column;
        padding-bottom: 250px;
    }

    .about-us-features-container {
        padding: 20px;
    }

    .about-us-main .about-us-image {
        height: 65vh;
    }

    .page-titles {
        padding-top: 30px;
        margin-bottom: 50px;
    }

    .contactMainContainer {
        padding-bottom: 250px;
    }

}