.contact-main {
    width: 100%;
    color: var(--secondary-color);
    min-height: 100vh;
    position: relative;
}

.contactMainContainer {
    width: 55%;
    margin: auto;
    padding: 0;
}

.contactMainContainer h1 {
    padding: 50px 0px;
    font-size: 40px;
    font-weight: 500;
    margin: 0;
}

.contactInformation {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 30px;
}

.india-Office p {
    margin: 0;
    font-size: 12px;
    line-height: 1.3em;
    font-style: italic;
}

.india-Office h4,
.contactTableHeading h4 {
    font-size: 20px;
    font-weight: 500;
}

.contactTableHeading .social {
    padding-top: 30px;
}


.contactMainTable td i {
    color: var(--secondary-color);
    font-size: 14px;
}

.contactMainTable .contactTableHeading {
    padding: 0px 80px 0px 0px;
}

.contactMainTable .contactTableSubHeading {
    padding: 0px 40px 0px 0px;
}

.contactSubTable td h5 {
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.contactSubTable td p a {
    line-height: 25px;
    text-decoration: none;
    font-size: 16px;
    color: var(--secondary-color);
}

.contactSubTable td p {
    margin: 0;
    padding-bottom: 15px;
    padding-top: 5px;
}

.mainAddress h4 {
    padding: 7px 0px;
    margin: 0;
}



/*****Getin Touch ****/

.getInTouchSection {
    padding-top: 80px;
    margin: 0;
    padding-bottom: 100px;
}

.contact-input-pair {
    display: flex;
    justify-content: space-between;
}

.contact-input-pair-wrapper {
    width: 100%;
}

.floating-label-group-space {
    margin: 0px 50px;
}

.contact-input-pair .floating-label-group {
    width: 100%;
}

.getInTouchSectionContainer input,
.getInTouchSectionContainer textarea {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--secondary-ultra-lite-color);
    text-indent: 2px;
    color: var(--secondary-color);
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    margin: 20px 0px;
}

.getInTouchSectionContainer input:hover,
.getInTouchSectionContainer textarea:hover {
    border-bottom: 2px solid var(--secondary-color);
}

.getInTouchSectionContainer input:focus,
.getInTouchSectionContainer textarea:focus {
    outline: none;
    border-bottom: 2px solid var(--primary-color);
}


.getInTouchSectionContainer textarea {
    height: 200px;
    margin-bottom: 50px;
}


.floating-label-group-button button {
    width: 100%;
    border: none;
    color: #fff;
    background-color: var(--secondary-color);
    text-transform: uppercase;
    padding: 18px 0px;
    margin: 18px 0px;
    font-weight: 600;
    font-size: 14px;
    transition: 0.3s;
    text-align: center;
    cursor: pointer;
    border-radius: 2px;
    margin-bottom: 100px;
}

.floating-label-group-button button:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.textAreaContactSection {
    padding-top: 5px;
}


.contact-us-social a i {
    font-size: 20px;
    transition: 0.3s;
    padding-top: 20px;
}

.contact-us-social a i:hover {
    color: var(--primary-color);
}

.contact-gmap {
    width: 100%;
    height: 80vh;
}

.contact-gmap iframe {
    width: 100%;
    height: 100%;
}

.getinTouch-loader {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.getinTouch-loader p {
    font-size: 18px;
}


@media(max-width: 850px) {
    .contactMainContainer {
        width: 80%;
        padding-bottom: 0;
    }

    .getInTouchSection {
        padding-bottom: 150px;
    }

    .contactMainContainer table h4 {
        font-size: 18px;
    }

    .contactMainContainer table p {
        font-size: 14px;
    }


}

@media(max-width: 550px) {
    .entry-titles {
        font-size: 40px;
    }

    .entry-subtitles p {
        font-size: 20px;
        line-height: normal;
        letter-spacing: normal;
    }

    .page-titles {
        width: 100%;
    }

    .contactMainContainer h1 {
        font-size: 26px;
    }

    .contactMainTable .contactTableHeading {
        padding: 0px 25px 0px 0px;
    }

    .contactMainTable .contactTableSubHeading {
        padding: 0px 15px 0px 0px;
    }

    .floating-label-group-space {
        margin: 0px 15px;
    }

    .contactMainContainer {
        padding: 15px;
        width: 97%;
    }

    #contact-address-tr {
        display: flex;
        flex-direction: column;
    }

    .india-Office {
        padding-bottom: 30px;
    }

    #contact-social-tr {
        display: block;
    }

    .getInTouchSection {
        padding-top: 30px;
    }

    .getInTouchSection {
        padding-bottom: 150px;
    }


}