.products-section {
    position: relative;
    scroll-behavior: smooth;
}

.products-container {
    width: 90%;
    margin: auto;
    padding: 10px 0px;
    min-height: 100vh;
    padding-bottom: 200px;
    scroll-behavior: smooth;
}

.product-title {
    color: var(--secondary-color);
    font-size: 18px;
    padding-top: 25px;
    margin: 0px;
    padding-bottom: 25px;
}

.product-header-container {
    padding-top: 25px;
    padding-bottom: 30px;
}

.product-header-container h2 {
    font-size: 18px;
    color: var(--secondary-lite-color);
    font-weight: 400;
}

.product-dropbtn {
    margin: 0px 10px;
}

.product-header-container button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.product-dropdown button h2 {
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: 500;
    display: flex;
    padding-bottom: 5px;
}

.product-dropdown button h2 i {
    padding-left: 15px;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.product-dropdown {
    position: relative;
    display: inline-block;
}

.product-dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    color: var(--secondary-color);
    cursor: pointer;
    font-size: 18px;
}

.product-dropdown-content a {
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    transition: 0.2s;
    color: var(--secondary-color);
    text-decoration: none;
}

.product-dropdown-content a:hover {
    background-color: var(--primary-color);
    color: #fff;
}

.product-dropdown-content.active {
    display: block;
}

a.product-dropdown-selected {
    background-color: var(--secondary-color);
    color: #fff;
}

.product-dropdown-selected h3,
.tempDropdown h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

.product-dropdown-selected p,
.tempDropdown p {
    margin: 0;
    padding-top: 5px;
    font-style: italic;
    font-size: 14px;
}



/*product-card*/
.product-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    text-decoration: none;
    transition: 0.3s;
    margin: 10px;
}

.product-card:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.product-card-img {
    width: 100%;
    padding-bottom: 100%;
    background-color: #eeeeee;
    position: relative;
}

.product-card-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
}

.product-card:hover img {
    mix-blend-mode: normal;
}

.min-details {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
}

.products-card-title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.product-card h1 {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    padding-bottom: 5px;
    margin: 0;
}

.product-card h2 {
    font-size: 14px;
    color: var(--secondary-color);
    font-weight: 500;
    margin: 0;
}

.products-card-temp-container {
    display: flex;
    align-items: center;
}

.products-card-temp-container h2 {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color);
    margin: 0;
}

.products-card-temp-container i {
    font-size: 22px;
    color: var(--primary-color);
    padding-right: 10px;
}




.filter-no-pro {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
    height: 85vh;
}

@media(max-width: 550px) {
    .product-dropdown-content {

        width: 200px;
        left: -20px;
    }

    .mb-5 {
        margin-bottom: 10px !important;
    }

    .min-details {
        padding: 10px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .products-card-title-container {
        padding-bottom: 10px;
    }

    .product-card h1 {
        font-size: 16px;

    }

    .product-card h2 {
        font-size: 14px;
    }

    .products-card-temp-container {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .products-card-temp-container h2 {
        font-size: 14px;
    }

    .products-card-temp-container i {
        font-size: 18px;
    }




}