.home-carousel-container {
    height: 100%;
    width: 100%;
}

.carousel.slide,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
    width: calc(100vw - 280px);
    height: calc(100vh - 100px);
}

.carousel.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel.slide video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.carousel-inner {
    width: 100%;
}

.carousel-caption {
    position: absolute;
    text-align: right;
    top: 22%;
    right: 60px;
    width: 50%;
}

.carousel-caption.mid {
    top: 30%;
}

.carousel-caption.start {
    top: 25%;
}

.slide-right {
    width: 100%;
    overflow: hidden;
    margin-left: 300px;
    max-width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.slide-right h2 {
    animation: 0.5s slide-right 0.5s forwards;
    transform: translateX(-100%);
    font-size: 72px;
    font-weight: 600;
    padding-bottom: 15px;
    line-height: 1em;
    font-family: "monserrat-black";
}

.slide-right a {
    border: 1px solid white;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    animation: 0.4s slide-right 0.4s forwards;
    transform: translateX(-1000%);
    transition: 0.3s;
    cursor: pointer;
}

.slide-right a:hover {
    background-color: white;
    color: black;
}

.slide-right a {
    text-decoration: none;
    color: white;
}



.span-and {
    font-size: 120px;
    padding-left: 0;
}

.span-of {
    font-size: 92px;
}


@keyframes slide-right {
    to {
        transform: translateX(0);
    }
}

@media(max-width: 1200px) {

    .slide-right {
        margin-left: 80px;
    }

    .carousel-caption {
        width: 70%;
    }

}

@media(max-width: 1100px) {

    .carousel.slide,
    .carousel-fade .carousel-item-next.carousel-item-start,
    .carousel-fade .carousel-item-prev.carousel-item-end,
    .carousel-fade .carousel-item.active {
        position: relative;
        width: 100vw;
    }

    .carousel-caption {
        width: 80%;
    }

    .slide-right {
        margin-left: 0px;
    }


}

@media(max-width: 750px) {
    .slide-right {
        margin-left: -40px;
    }

    .carousel-caption {
        width: 80%;

    }

    .slide-right h2 {
        font-size: 52px;
    }

    .slide-right .span-and {
        font-size: 65px;
    }

    .slide-right .span-of {
        font-size: 72px;
    }

}

@media(max-width: 550px) {
    .slide-right {
        margin-left: -40px;
    }

    .carousel-caption {
        width: 90%;

    }

    .slide-right h2 {
        font-size: 44px;
    }

    .slide-right .span-and {
        font-size: 65px;
    }

    .slide-right .span-of {
        font-size: 62px;
    }





}