.product-page-main {
    height: 100%;
    width: 100%;
    position: relative;
}

.product-summary-container {
    width: 100%;
    min-height: 100%;
    padding-bottom: 150px;
}

.product-summary-header {
    width: 97%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}

.ps-nav-container {
    display: flex;
    align-items: center;
    list-style: none;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.ps-nav-container li {
    padding-right: 7px;
    height: 100%;
    display: flex;
    align-items: center;
}

.ps-nav-container li a {
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    color: var(--secondary-color);
    transition: 0.5s;
}

.ps-nav-container li i {
    font-size: 11px;
    text-decoration: none;
    padding-left: 3px;
    color: var(--secondary-color);
}

.ps-nav-container li a:hover {
    color: var(--primary-color);
}

.ps-nav-container-pn {
    height: 100%;
    display: flex;
    align-items: center;
}

.ps-nav-container-pn a {
    margin: 0px;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    color: var(--secondary-color);
    transition: 0.5s;
    width: 35px;
}

.ps-nav-container-pn a:hover {
    color: var(--primary-color);
    font-weight: 600;
}

.ps-details-container {
    height: 100%;
    padding: 25px 0px;
    display: flex;
    align-items: flex-start;
    padding-bottom: 80px;
}


.ps-image-section {
    flex: 2;
}

.ps-image-conatiner {
    width: 90%;
    margin-left: auto;
}

.ps-image-wrapper {
    width: 70%;
    margin-right: auto;
    mix-blend-mode: darken;
}

.ps-image-wrapper img {
    width: 100%;
    height: 100%;
}

.ps-details-data-section {
    flex: 1.5;
}

.ps-details-data-container {
    width: 90%;
    margin-right: auto;
}

.ps-details-data-title h1 {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 10px;
    text-transform: uppercase;
    color: var(--primary-color);
}

.ps-details-data-title h2 {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    color: var(--secondary-color);
    text-transform: capitalize;
}


.ps-details-data-header {
    display: flex;
    height: 80px;
    align-items: center;
}

.ps-details-data-title {
    flex: 8;
}

.ps-details-data-temperature {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 2;
}

.ps-details-data-temperature {
    height: 100%;
}

.ps-details-data-temperature-image-wrapper i {
    font-size: 50px;
    color: var(--primary-color);
    padding-right: 15px;
    height: 100%;
}

.ps-details-data-temperature h1 {
    font-size: 32px;
    padding-left: 10px;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 0;
}




.ps-details-data-body p {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    color: var(--secondary-color);
    text-align: justify;
    line-height: 1.5em;
    padding-bottom: 10px;
    padding-top: 25px;
}


.prod-prop {
    width: fit-content;
    margin-top: 10px;
    margin-right: 25px;
}


.pc-physical-dimentions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.prod-prop h3 {
    font-size: 15px;
    color: var(--primary-color);
    font-weight: 600;
    padding-top: 10px;
}




.ps-button-wrapper {
    display: flex;
    padding-top: 25px;
}

.prod-prop-quantity {
    flex: 1;
    height: 100%;
}


.prod-prop-quantity .wrapper {
    color: var(--secondary-color);
    display: flex;
    width: 125px;
    padding: 7px 10px;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--secondary-lite-color);
    border-radius: 3px;
    height: 50px;

}


.prod-prop-quantity .wrapper span {
    cursor: pointer;
    font-size: 16px;
    color: var(--secondary-color);
    font-weight: 500;
}




.prod-prop-quantity h3 {
    font-size: 15px;
    color: var(--primary-color);
    font-weight: 600;
    padding-bottom: 10px;
}

.ps-add-to-cart-btn {
    width: 100%;
    height: 50px;
    margin-top: 35px;
    margin-left: 25px;
    border: none;
    border-radius: 3px;
    background-color: var(--secondary-color);
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;
}

.ps-add-to-cart-btn.hover:hover {
    background-color: var(--primary-color);
    color: white;
}

.ps-add-to-cart-btn.no-hover:hover {
    background-color: var(--secondary-color);
    cursor: not-allowed;
}

.prod-download-section {
    display: flex;
    align-items: center;
    padding-top: 40px;
    text-decoration: none;
    width: fit-content;
}

.prod-download-section p {
    margin: 0;
    padding: 0;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
}

.prod-download-section i {
    color: var(--primary-color);
    transition: 0.3s;
    cursor: pointer;
}

.prod-download-section i:hover {
    color: var(--secondary-color);
}


.cart-notifier {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    align-items: center;
    background-color: #8acfab;
    justify-content: space-between;
    padding: 0px 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.5s ease-in-out;
    z-index: 5;
    display: none;
}

.cart-notifier.show {
    display: flex;
}

.cart-notifer-img-wrapper {
    width: 50px;
    height: 50px;
}

.cart-notifer-img-wrapper img {
    width: 100%;
    height: 100%;
}

.cart-notifier-start {
    display: flex;
    align-items: center;
}

.cart-notifier-start i {
    font-size: 40px;
    color: white;
}

.cart-notifier-start h2 {
    font-size: 14px;
    color: white;
    padding: 0;
    margin: 0;
    padding-left: 40px;
    font-weight: 600;
}

.cart-notifier-end {
    display: flex;
    align-items: center;
}

.cart-notifier-end a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-right: 40px;
    transition: 0.3s;
    border-bottom: 2px solid white;
}

.cart-notifier-end a:hover {
    color: var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
}

.cart-notifier-end i {
    font-size: 22px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}

.cart-notifier-end i:hover {
    color: var(--secondary-color);
}



.phy-prop-select {
    display: flex;
    color: var(--secondary-color);
    align-items: center;
    font-size: 14px;
    border-bottom: 1px solid var(--secondary-color);
    width: fit-content;
    padding-bottom: 5px;
    position: relative;
    cursor: pointer;
}

.phy-prop-select i {
    height: 100%;
    padding-left: 5px;
    transition: 0.3s;
    cursor: pointer;
}

.phy-prop-select i:hover {
    color: var(--primary-color);
}

.phy-prop-option {
    position: absolute;
    background-color: white;
    flex-direction: column;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    cursor: pointer;
    width: fit-content;
    display: none;
    max-height: 280px;
    overflow-y: auto;
}

.phy-prop-option.active {
    display: flex;
}

.phy-prop-option h4 {
    font-size: 14px;
    padding: 8px 10px;
    margin: 0;
    transition: 0.1s;
}

.phy-prop-option h4:hover {
    background-color: var(--primary-color);
    color: white;
}

.phy-prop-selected {
    background-color: var(--secondary-color);
    color: white;
}

.customized-inputbox {
    display: flex;
    align-items: center;
}

.customized-inputbox input {
    margin-top: 10px;
    border: none;
    border: 1px solid var(--secondary-color);
    width: 100px;
    font-size: 13px;
    padding: 5px;
    height: 30px;
    border-radius: 5px;

}

.customized-inputbox input:focus {
    border: none;
    outline: none;
    border: 1px solid var(--primary-color);

}

.customized-inputbox button {
    background-color: var(--primary-color);
    color: white;
    height: 30px;
    border: none;
    padding: 0px 5px;
    margin: 0;
    margin-left: 5px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}

@media(max-width: 850px) {

    .ps-image-section {
        width: 100%;
    }

    .ps-details-data-section {
        width: 100%;
    }

    .ps-details-container {
        flex-direction: column;
        padding: 20px 50px;
        padding-bottom: 100px;
    }

    .ps-image-conatiner {
        width: 100%;
    }

    .ps-details-data-container {
        width: 100%;
    }

    .ps-image-wrapper {
        width: 70%;
        margin: auto;
    }
}

@media(max-width: 550px) {
    .ps-image-wrapper {
        width: 100%;
        margin: auto;
    }

    .product-summary-header {
        display: none;
    }

    .ps-details-container {
        padding-top: 50px;
    }



    .ps-details-data-header {
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
    }

    .ps-details-data-temperature h1 {
        font-size: 22px;
        margin-bottom: 3px;
    }

    .ps-details-data-temperature {
        display: flex;
        align-items: center;
        padding-top: 20px;
    }

    .ps-details-data-temperature i {
        font-size: 28px;
        padding-right: 5px;
    }

    .ps-details-data-temperature-image-wrapper {
        height: 100%;

    }

    .ps-details-data-section {
        padding-top: 40px;
    }

    .ps-add-to-cart-btn {
        font-size: 14px;
    }


}