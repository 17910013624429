/*FORGOT PASSWORD*/
.forgotPasswordSection {
    position: relative;
    min-height: 100vh;
    padding-bottom: 150px;
}

.forgotPasswordContainer {
    padding-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.forgotPasswordContainer * {
    color: var(--secondary-color);
}

.forgotPasswordContainer h1 {
    font-size: 55px;
    margin-bottom: 70px;
    font-weight: 400;
}

.forgotPasswordContainer p {
    width: 25%;
    text-align: justify;
    line-height: 22px;
    font-weight: 400;
}


.forgotPasswordContainer .floating-label-group {
    width: 25%;
    margin-top: 15px;
}

.forgotPasswordContainer .floating-label-group input {
    margin: 5px 0px;
}


.forgotPasswordContainer input {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--secondary-ultra-lite-color);
    text-indent: 2px;
    color: var(--secondary-color);
    width: 100%;
    border-radius: 0px;
}

.forgotPasswordContainer input:hover {
    border-bottom: 2px solid var(--secondary-color);
}

.forgotPasswordContainer input:focus {
    outline: none;
    border-bottom: 2px solid var(--primary-color);
}

.forgotPasswordContainer button,
.go-back-to-home a,
.forgotPasswordContainer a {
    width: 25%;
    background: var(--secondary-color);
    padding: 15px 20px;
    color: #fff !important;
    text-align: center;
    margin: 50px 0px;
    margin-bottom: 60px;
    transition: 0.3s;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 2px;
    text-decoration: none !important;
}

.forgotPasswordContainer button:hover,
.go-back-to-home a:hover,
.forgotPasswordContainer a:hover {
    background-color: var(--primary-color);
}

.go-back-to-home a {
    width: 30% !important;
}

.go-back-to-home {
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
}

.go-back-to-home a {
    text-decoration: none;
}

.email-sent-msg {
    width: 50% !important;
    margin: auto;
    background-color: #f8f5f5;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center !important;
}

.email-sent-msg.pl {
    width: 40%;
    text-align: center;
}



@media(max-width: 850px) {
    .forgotPasswordContainer h1 {
        font-size: 50px;
        margin-bottom: 35px;
    }

    .forgotPasswordContainer p {
        width: 70%;
    }

    .forgotPasswordContainer .floating-label-group {
        width: 70%;
        margin-top: 15px
    }

    .forgotPasswordContainer a {
        width: 70%;
    }
}

@media(max-width: 550px) {
    .forgotPasswordContainer h1 {
        font-size: 36px;
        margin-bottom: 35px;
    }

    .forgotPasswordContainer p {
        width: 80%;
    }

    .forgotPasswordContainer .floating-label-group {
        width: 80%;
        margin-top: 15px
    }

    .forgotPasswordContainer a {
        width: 80%;
    }

    .forgotPasswordContainer button {
        width: 80%;

    }
}