@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'of';
  src: url('./../public/Asserts/Fonts/AdineKirnberg-Alternate.ttf') format('truetype');
}

@font-face {
  font-family: 'monserrat-black';
  src: url('./../public/Asserts/Fonts/Montserrat-Regular.ttf') format('truetype');
}


:root {
  --primary-color: #2d4798;
  --secondary-color: #4b4a4c;
  --secondary-lite-color: #999999;
  --secondary-ultra-lite-color: #e0e0e0;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Bai Jamjuree', sans-serif;
}

input::placeholder {
  color: var(--secondary-color);
}

/*hover underline effect*/

.hv-underline {
  position: relative;
  color: var(--secondary-color);
  transition: transform 0.4s ease-in-out;
  padding-bottom: 5px;
  padding-top: 5px;
}


.hv-underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2.5px;
  background-color: var(--primary-color);
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.hv-underline:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}

/* Floating label styles */
.floating-label-group {
  position: relative;
}

.floating-label-group .floating-label {
  color: var(--secondary-color);
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 0px;
  font-size: 15px;
  transition: all 0.4s ease-out;
}

/***inputbox***/
.floating-label-group input:focus~.floating-label,
.floating-label-group input:not(:focus):valid~.floating-label {
  top: -36px;
  bottom: 0px;
  left: 0px;
  padding-top: 18px;
  color: var(--secondary-lite-color);
  font-size: 13px;
  font-weight: 400;
}

.floating-label-group input:focus,
.floating-label-group textarea:focus {
  outline: none;
  border-bottom: 2px solid var(--primary-color) !important;
}

.floating-label-group input {
  font-size: 16px;
  padding: 5px 0px;
}

.floating-label-group.bottom input {
  margin-bottom: 22px;
}


.floating-label-group input:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid var(--primary-color);
}

/***textarea***/
.floating-label-group textarea:focus~.floating-label,
.floating-label-group textarea:not(:focus):valid~.floating-label {
  top: -36px;
  bottom: 0px;
  left: 0px;
  padding-top: 18px;
  color: var(--secondary-lite-color);
  font-size: 13px;
  font-weight: 400;

}

.floating-label-group textarea {
  margin: 40px 0px;
  font-size: 16px;
  padding: 5px 0px;
}

.floating-label-group.bottom textarea {
  margin-bottom: 22px;
}


.floating-label-group textarea:focus {
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid var(--primary-color);
}

/* Hide spinners for input type number in Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners for input type number in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}