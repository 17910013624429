.footerContainer {
    width: 100%;
    background-color: #fff;
    color: var(--secondary-color);
    text-align: center;
    cursor: pointer;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
}

.footerMain {
    margin: auto;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.footerItem {
    flex: 1;
}

.footerItem.start {
    height: 100%;
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}


.footerItem.center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.footerItem.center a {
    padding: 0;
    margin: 0;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    padding: 0px 10px;
    color: var(--secondary-color);
    font-weight: 500;
    transition: 0.2s;
}

.footerItem.center a:hover {
    color: black;
}

.footerItem.end {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 0;
}

.end-bas {
    font-size: 18px;
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 600;
    padding-right: 30px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}

.end-bas:hover {
    color: var(--secondary-color);
}

.social-footer {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
    margin: 0;
}

.social-footer a {
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    padding-left: 15px;
    color: var(--secondary-color);
    transition: 0.3s;
    cursor: pointer;
}

.social-footer i {
    padding: 0;
    margin: 0;
    font-size: 20px;
}

.social-footer a:hover i {
    color: var(--primary-color);
}


@media(max-width: 850px) {
    .footerContainer {
        height: 180px;
        padding: 30px 0px;
    }

    .end-bas {
        display: none;
    }

    .footerMain {
        flex-direction: column-reverse;
    }

    .footerItem.start {
        text-align: center;
        padding-top: 10px;
        border-top: 1px solid var(--secondary-ultra-lite-color);
        font-size: 12px;
    }

    .footerItem.center {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .footerItem.center a {
        font-size: 13px;
    }

    .footerItem.end a i {
        font-size: 18px;
    }
}